import { useLazyQuery, useMutation } from '@apollo/client';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CartAtom } from '~/services/cart';
import { UserAtom } from '~/services/user';
import { transFormCart } from '~/utils/transform';
import {
    ADD_CONFIGURABLE_PRODUCT_TO_CART_GQL,
    APPLY_COUPON_CODE,
    CREATE_EMPTY_CART_GQL,
    GET_CART_INFO_GQL,
    MERGE_CART_GQL,
    PLACE_ORDER_GQL,
    REMOVE_COUPON_CODE,
    REMOVE_FROM_CART,
    SET_BILLING_ADDRESS_GQL,
    SET_EMAIL_ON_CART_GQL,
    SET_ORDER_NOTE,
    SET_PAYMENT_METHOD_GQL,
    SET_SHIPPING_ADDRESS_GQL,
    SET_SHIPPING_METHOD_GQL,
    UPDATE_CART_PRODUCT_GQL
} from './gql';

export const useCart = () => {
    const pathname = usePathname();
    const router = useRouter();
    const cart = useRecoilValue(CartAtom.cartSelector);
    const setCart = useSetRecoilState(CartAtom.cart);
    const resetCart = useResetRecoilState(CartAtom.cart);
    const resetCurrentUser = useResetRecoilState(UserAtom.currentUser);
    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expired-time');

        resetCurrentUser();
        const timeOut = setTimeout(() => {
            window.location.reload();
            clearTimeout(timeOut);
        }, 100);
        if (pathname !== '/') {
            router.push('/');
        }
    }, []);

    const [createEmptyCart] = useMutation(CREATE_EMPTY_CART_GQL);
    const [mergeCart] = useMutation(MERGE_CART_GQL, {
        onError: ({ message }) => {
            toast.error(message);
            resetCart();
            logout();
        }
    });

    const [getCartInfo, { loading: loadingCart, data: cartDataInfo, refetch: refetchGetCart, called }] = useLazyQuery(
        GET_CART_INFO_GQL,
        {
            fetchPolicy: 'network-only',
            onCompleted(data: any) {
                return setCart((prevCart) => ({
                    ...prevCart,
                    ...transFormCart(data),
                    loading: false
                }));
            },
            onError(error) {
                setCart((prevCart) => ({
                    ...prevCart,
                    loading: false
                }));
                console.log(error);
            }
        }
    );

    const handleRefetch = useCallback(() => {
        setCart((prevCart) => ({
            ...prevCart,
            loading: true
        }));
        getCartInfo({
            variables: {
                cartId: cart.cartId
            }
        }).then(({ data }) => {
            // Handle the refetched data
            if (data) {
                setCart((prevCart) => ({
                    ...prevCart,
                    ...transFormCart(data),
                    loading: false
                }));
            }
        });
    }, [cart.cartId]);

    const [addToCart, { loading: loadingAddToCart }] = useMutation(ADD_CONFIGURABLE_PRODUCT_TO_CART_GQL, {
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.addConfigurableProductsToCart),
                loading: false,
                open: !!clientOptions?.variables?.openCart
            }));
            // !clientOptions?.variables?.isByNow &&
            //   toast.success("Product has been added successfully");
        },
        onError(error, clientOptions) {
            toast.error(error.message);
        }
    });

    const [updateCartItems, { loading: loadingUpdateCartItems }] = useMutation(UPDATE_CART_PRODUCT_GQL, {
        onError(error) {
            toast.error(error.message);
        },
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.updateCartItems),
                loading: false
            }));
        }
    });

    const [applyCouponCode, { loading: loadingApplyCouponCode }] = useMutation(APPLY_COUPON_CODE, {
        fetchPolicy: 'network-only',
        onError(error) {
            toast.error(error.message);
        },
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.applyCouponToCart),
                loading: false
            }));
        }
    });

    const [removeCouponCode, { loading: loadingRemoveCouponCode }] = useMutation(REMOVE_COUPON_CODE, {
        fetchPolicy: 'network-only',
        onError(error) {
            toast.error(error.message);
        },
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.removeCouponFromCart),
                loading: false
            }));
        }
    });

    const [removeCartItem, { loading: loadingRemoveCartItem }] = useMutation(REMOVE_FROM_CART, {
        onError(error) {
            toast.error(error.message);
        },
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.removeItemFromCart),
                loading: false
            }));
        }
    });

    // checkout
    const [setBillingAddress, { loading: loadingBilling }] = useMutation(SET_BILLING_ADDRESS_GQL, {
        fetchPolicy: 'network-only',
        onError: ({ message }) => {
            toast.error(message);
        },
        onCompleted(data, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.setBillingAddressOnCart),
                loading: false
            }));
        }
    });

    const [setShippingAddress, { loading: loadingShipping }] = useMutation(SET_SHIPPING_ADDRESS_GQL, {
        fetchPolicy: 'network-only',
        onError: ({ message }) => {
            toast.error(message);
        }
    });

    const [setEmailOnCart, { loading: loadingEmailOnCart }] = useMutation(SET_EMAIL_ON_CART_GQL, {
        onError: ({ message }) => {
            toast.error(message);
            setCart((prevCart) => ({
                ...prevCart,
                loading: false
            }));
        },
        onCompleted(response, clientOptions) {
            setCart((prevCart) => ({
                ...prevCart,
                // email: newCart.email,
                ...transFormCart(response?.setGuestEmailOnCart),
                loading: false
            }));
        }
    });

    const [setPaymentMethod, { loading: loadingPaymentMethod }] = useMutation(SET_PAYMENT_METHOD_GQL, {
        fetchPolicy: 'no-cache',
        onError(error, clientOptions) {
            toast.error(error.message);
        },
        onCompleted(data, clientOptions) {
            return setCart((prevCart) => ({
                ...prevCart,
                ...transFormCart(data?.setPaymentMethodOnCart),
                loading: false
            }));
        }
    });

    const [setShippingMethod, { loading: loadingShippingMethod }] = useMutation(SET_SHIPPING_METHOD_GQL, {
        onError: ({ message }) => {
            toast.error(message);
        },
        onCompleted(res) {
            setCart((prev) => ({
                ...prev,
                ...transFormCart(res?.setShippingMethodsOnCart),
                loading: false
            }));
        }
    });

    const [mutationPlaceOrder, { loading: loadingPlaceOrder }] = useMutation(PLACE_ORDER_GQL, {
        onError: ({ message }) => {
            toast.error(message);
        }
    });
    const [mutationOrderNote] = useMutation(SET_ORDER_NOTE, {
        onError: ({ message }) => {
            toast.error(message);
        }
    });

    useEffect(() => {
        const _loading = loadingEmailOnCart || loadingShippingMethod || loadingPaymentMethod || loadingCart;

        if (!!_loading) {
            setCart((prevCart) => ({
                ...prevCart,
                loading: true
            }));
        }
    }, [loadingEmailOnCart, loadingShippingMethod, loadingPaymentMethod, loadingCart]);

    useEffect(() => {
        setCart((prevCart) => ({ ...prevCart, loadingApplyCouponCode }));
    }, [loadingApplyCouponCode]);

    useEffect(() => {
        setCart((prevCart) => ({ ...prevCart, cartCalled: !!called }));
    }, [called]);

    return {
        addToCart,
        mergeCart,
        updateCartItems,
        createEmptyCart,
        removeCartItem,
        getCartInfo,
        applyCouponCode,
        removeCouponCode,
        setBillingAddress,
        setShippingAddress,
        setEmailOnCart,
        setPaymentMethod,
        setShippingMethod,
        mutationPlaceOrder,
        mutationOrderNote,
        loadingCart,
        loadingAddToCart,
        loadingUpdateCartItems,
        loadingRemoveCartItem,
        loadingRemoveCouponCode,
        loadingApplyCouponCode,
        loadingBilling,
        loadingShippingMethod
    };
};
