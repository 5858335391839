import { gql } from "@apollo/client";

const CART_DATA = gql`
  fragment CartData on Cart {
    email
    billing_address {
      city
      country {
        code
        label
      }
      firstname
      lastname
      postcode
      region {
        code
        label
      }
      street
      telephone
    }
    shipping_addresses {
      firstname
      lastname
      street
      city
      company
      postcode
      region {
        code
        label
      }
      country {
        code
        label
      }
      telephone
      available_shipping_methods {
        amount {
          currency
          value
        }
        available
        carrier_code
        carrier_title
        error_message
        method_code
        method_title
        price_excl_tax {
          value
          currency
        }
        price_incl_tax {
          value
          currency
        }
      }
      selected_shipping_method {
        amount {
          value
          currency
        }
        carrier_code
        carrier_title
        method_code
        method_title
      }
    }
    items {
      uid
      id
      prices {
        discounts {
          amount {
            value
            currency
            __typename
          }
          label
        }
      }
      product {
        id
        thumbnail {
          url
          label
        }
        name
        url_key
        sku
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              url_key
              sku
              thumbnail {
                url
                label
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
      quantity
      product_note {
        name
        type
        value
      }
      ... on ConfigurableCartItem {
        configurable_options {
          configurable_product_option_value_uid
          option_label
          value_label
        }
      }
    }
    available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
      title
    }
    applied_coupons {
      code
    }
    prices {
      subtotal_including_tax {
        value
        currency
      }
      subtotal_excluding_tax {
        value
        currency
      }
      grand_total {
        value
        currency
      }
      applied_taxes {
        amount {
          value
          currency
        }
        label
      }
      discounts {
        amount {
          value
          currency
          __typename
        }
        label
      }
    }
  }
`;

export const CREATE_EMPTY_CART_GQL = gql`
  mutation {
    createEmptyCart
  }
`;
export const GET_CART_INFO_GQL = gql`
  query Cart($cartId: String!) {
    cart(cart_id: $cartId) {
      ...CartData
    }
  }
  ${CART_DATA}
`;

export const ADD_CONFIGURABLE_PRODUCT_TO_CART_GQL = gql`
  mutation AddConfigurableProductsToCart(
    $sku: String!
    $quantity: Float!
    $cartId: String!
    $parentSku: String!
    $productNote: [ProductNoteInput]
  ) {
    addConfigurableProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          {
            parent_sku: $parentSku
            data: { quantity: $quantity, sku: $sku }
            product_note: $productNote
          }
        ]
      }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;
// https://developer.adobe.com/commerce/webapi/graphql-api/index.html#mutation-applyCouponToCart
// https://developer.adobe.com/commerce/webapi/graphql-api/index.html#definition-ApplyCouponToCartOutput
export const APPLY_COUPON_CODE = gql`
  mutation ApplyCouponToCart($cartId: String!, $couponCode: String!) {
    applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
      cart {
        ...CartData
        __typename
      }
    }
  }
  ${CART_DATA}
`;

export const REMOVE_COUPON_CODE = gql`
  mutation RemoveCouponFromCart($cartId: String!) {
    removeCouponFromCart(input: { cart_id: $cartId }) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

export const REMOVE_FROM_CART = gql`
  mutation RemoveItemFromCart($cartId: String!, $cartItemId: Int!) {
    removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;
export const UPDATE_CART_PRODUCT_GQL = gql`
  mutation UpdateCartItems(
    $cartId: String!
    $cartItemUId: ID!
    $quantity: Float!
  ) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: [{ cart_item_uid: $cartItemUId, quantity: $quantity }]
      }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

// https://developer.adobe.com/commerce/webapi/graphql-api/index.html#definition-SetBillingAddressOnCartInput
export const SET_BILLING_ADDRESS_GQL = gql`
  mutation SetBillingAddressesOnCart(
    $address: CartAddressInput
    $cart_id: String!
    $same_as_shipping: Boolean
    $customer_address_id: Int
  ) {
    setBillingAddressOnCart(
      input: {
        cart_id: $cart_id
        billing_address: {
          address: $address
          same_as_shipping: $same_as_shipping
          customer_address_id: $customer_address_id
        }
      }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;
// https://developer.adobe.com/commerce/webapi/graphql-api/index.html#definition-SetShippingAddressesOnCartInput
export const SET_SHIPPING_ADDRESS_GQL = gql`
  mutation SetShippingAddressesOnCart(
    $address: CartAddressInput
    $cart_id: String!
    $customer_address_id: Int
  ) {
    setShippingAddressesOnCart(
      input: {
        cart_id: $cart_id
        shipping_addresses: [
          { address: $address, customer_address_id: $customer_address_id }
        ]
      }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

export const SET_EMAIL_ON_CART_GQL = gql`
  mutation SetGuestEmailOnCart($cartId: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

export const GET_AVAILABLE_COUNTRIES = gql`
  query countries {
    countries {
      id
      full_name_english
      full_name_locale
      available_regions {
        id
        name
        code
      }
      is_selected
    }
  }
`;
export const SET_PAYMENT_METHOD_GQL = gql`
  mutation SetPaymentMethodOnCart(
    $cartId: String!
    $paymentMethodCode: String!
  ) {
    setPaymentMethodOnCart(
      input: { cart_id: $cartId, payment_method: { code: $paymentMethodCode } }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

export const GET_STRIPE_CONFIG = gql`
  query storeConfig {
    storeConfig {
      stripe_mode
      stripe_live_pk
      stripe_test_pk
    }
  }
`;
export const PLACE_ORDER_GQL = gql`
  mutation PlaceOrder($cartId: String!) {
    placeOrder(input: { cart_id: $cartId }) {
      order {
        order_number
      }
    }
  }
`;

export const SET_SHIPPING_METHOD_GQL = gql`
  mutation setShippingMethodsOnCart(
    $cartId: String!
    $carrierCode: String!
    $methodCode: String!
  ) {
    setShippingMethodsOnCart(
      input: {
        cart_id: $cartId
        shipping_methods: [
          { carrier_code: $carrierCode, method_code: $methodCode }
        ]
      }
    ) {
      cart {
        ...CartData
      }
    }
  }
  ${CART_DATA}
`;

export const MERGE_CART_GQL = gql`
  mutation MergeCarts($guestCartId: String!, $customerCartId: String!) {
    mergeCarts(
      source_cart_id: $guestCartId
      destination_cart_id: $customerCartId
    ) {
      id
    }
  }
`;

export const SET_ORDER_NOTE = gql`
  mutation ($cart_id: String!, $message: String!) {
    createOrderComment(input: { cart_id: $cart_id, comment: $message }) {
      result
    }
  }
`;
